const formAbandonment = ({prevLocation}) => {
    const dataLayer = window.dataLayer || [];

    if (prevLocation && prevLocation.pathname.includes('demo') || prevLocation && prevLocation.pathname.includes('contact') || prevLocation && prevLocation.pathname.includes('analysis-of-japans-jleague-ebook')) {
        let pushData = null;

        window.HUDL.forms = window.HUDL.forms.filter(form => {
            const interacted = form.history.length > 0;

            if (interacted === true) {
                pushData = {
                    event: 'FormAbandonmentHistory',
                    eventCategory: 'Form Abandonment',
                    eventAction: form.history.join('>'),
                    eventLabel: form.id
                };
            }
            else {
                pushData = {
                    event: 'formAbandonment_NoInteraction',
                    eventCategory: 'Form Abandonment',
                    eventAction: 'No Interaction',
                    eventLabel: form.id
                };
            }

            window.HUDL.forms
                .filter(theForm => theForm.id === form.id)
                .forEach(theForm => theForm.removeFormListener());
            dataLayer.push(pushData);
            return false;
        });
    }
};

// eslint-disable-next-line import/prefer-default-export
export {formAbandonment};
