import React from 'react';
import {formAbandonment} from '@helpers/formAbandonment';
import {defaultLanguageInfo} from '@helpers/getLanguageInfo';

import {MenuProvider} from './src/contextModels/MenuContext';
import {ModalProvider} from './src/contextModels/ModalContext';

if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]', {
        speed: 500,
        speedAsDuration: true,
        offset: 100,
        easing: 'easeInOutCubic'
    });
}

export const wrapRootElement = ({element}) => (
    <ModalProvider>
        <MenuProvider>{element}</MenuProvider>
    </ModalProvider>
);

export const onClientEntry = () => {
    if (typeof window === 'undefined') {
        return;
    }

    const pathName = window.location.pathname;

    if (pathName === '/') {
        return;
    }

    const currentSiteLanguages = Object.values(defaultLanguageInfo.languages).map(({path}) => path);

    const langCode = pathName.split('/')[1];

    if (currentSiteLanguages.includes(langCode)) {
        return;
    }

    const siteDefaultLanguagePath = Object.values(defaultLanguageInfo.languages)[0].path;

    window.location.href = `/${siteDefaultLanguagePath}${pathName}`;
};

/**
 * @description Called when the initial (but not subsequent) render of Gatsby App is done on the client.
 */
export const onInitialClientRender = () => {
    // Netlify Identity Widget
    if (window.netlifyIdentity) {
        window.netlifyIdentity.on('init', user => {
            if (!user) {
                window.netlifyIdentity.on('login', () => {
                    document.location.href = '/admin/';
                });
            }
        });
    }
};

/**
 * @param  {object} prevLocation - Object object of previously visited page.
 * @param  {string} prevLocation.pathname - String of previously visited page.
 * @description Pushed to the datalayer if leaving a form page before submitting.
 */
export const onPreRouteUpdate = ({prevLocation}) => {
    formAbandonment({prevLocation});
};

/**
 * @param {Object} pageData - Object of the default objects.
 * @param {Object} pageData.location - A location object.
 * @param {Object} pageData.prevLocation - The previous location object.
 * @description Every time the user changes route, we push an object to the data layer.
 */
export const onRouteUpdate = (pageData) => {
    const {prevLocation} = pageData;
    const previousPage = prevLocation ? prevLocation.pathname : '';

    window.HUDL = window.HUDL || {};
    window.HUDL.previousPath = previousPage;
};

export const shouldUpdateScroll = () => {
    window.scrollTo({
        top: 0,
        behavior: 'instant'
    });
    return false;
};
