import React, {createContext, useEffect, useRef, useState} from 'react';

const ModalContext = createContext([ {}, () => {} ]);

const ModalProvider = ({children}) => {
    const modalRef = useRef();
    const [ context, setContext ] = useState();

    useEffect(() => {
        setContext(modalRef.current);
    }, []);

    return (
        <>
            <ModalContext.Provider value={context}>
                {children}
            </ModalContext.Provider>
            <div ref={modalRef} />
        </>
    );
};

export {ModalContext, ModalProvider};
