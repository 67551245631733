import languageInfo from '../../config/languageInfo.json';

/**
 * @param {string} language The environment Variable GATSBY_SITE_LANGUAGE is passed into the function.
 * @returns {object} Returns an object of the current language information.
 * @description The function returns vital information needed to build and serve the website with the correct language information.
 */

export const getLanguageInfo = language => {
    if (typeof languageInfo[language] === 'undefined') {
        return {};
    }

    return languageInfo[language];
};

export const defaultLanguageInfo = getLanguageInfo(process.env.GATSBY_SITE_LANGUAGE);

const getLanguageInfoByLangCode = languageCode =>
    Object.values(languageInfo).find(value => value.languageCode === languageCode);

const getLanguageProperty = propertyName => languageCode =>
    getLanguageInfoByLangCode(languageCode)?.[propertyName];

export const getLanguageName = getLanguageProperty('languageName');
export const getLanguageFonts = getLanguageProperty('fonts');
